










































































import { mapActions, mapMutations, mapState } from 'vuex';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { Status } from '@/helpers/operatorHelpers';
import { deploySmartContract, mintOperator } from '@/api/SuperAdmin';

import type { SuperAdminGameProviderOperator } from '@/api/schema';
import OperatorDropdown from '@/components/operator/OperatorDropdown.vue';
import { SuperAdminMenuLink } from '@/api/schema';

const SuperAdminCreateOperatorDialog = (): any =>
  import(
    './SuperAdminCreateOperatorDialog/SuperAdminCreateOperatorDialog.vue'
  );

const SuperAdminOperatorViewMenu = (): Promise<any> =>
  import(
    '@/views/SuperAdminViews/super-admin-operator-view-menu/SuperAdminOperatorViewMenu.vue'
  );

const ConfirmationPromiseDialog = (): any =>
  import('@/components/ConfirmationPromiseDialog.vue');

export default {
  name: 'SuperAdminOperatorView',
  components: {
    OperatorDropdown,
    SuperAdminOperatorViewMenu,
    SuperAdminCreateOperatorDialog,
    ConfirmationPromiseDialog
  },
  data: (): any => ({
    Status,
    activeOperatorId: 0,
    activeOperator: null,
    openCreateAccountDialog: false,
    isLoading: {
      mint: false,
      deploy: false
    }
  }),
  computed: {
    ...mapState('SuperAdmin', {
      operator: 'operator',
      account: 'currentAccount'
    }),
    ...mapState('Operator', ['loading']),
    ...mapState('Auth', ['tokenSymbol']),
    operators(): SuperAdminGameProviderOperator[] {
      return [...(this.account?.operators || [])].sort(
        (a, b) => a.test - b.test
      );
    },
    menu(): SuperAdminMenuLink[] {
      return [
        {
          title: 'Operator Balance',
          icon: 'mdi-cash',
          routeName: 'account-operator-balance'
        },
        {
          icon: 'mdi-chart-box-outline',
          title: 'Home',
          routeName: 'account-operator-home'
        },
        {
          icon: 'mdi-bell-cog-outline',
          title: 'Loyalty Promotion',
          routeName: 'account-operator-loyalty-promotion'
        },
        {
          icon: 'mdi-view-dashboard-outline',
          title: 'Dashboard',
          routeName: 'account-operator-dashboard'
        },

        {
          title: 'Programs',
          icon: 'mdi-file-cog-outline',
          routeName: 'account-operator-programs',
          children: [
            {
              title: 'Play to Earn',
              routeName: 'account-operator-programs-p2e'
            },
            {
              title: 'Hold to Earn',
              routeName: 'account-operator-programs-h2e'
            },
            {
              title: 'Marketing Campaigns',
              routeName: 'account-operator-programs-promo'
            },
            {
              title: 'Missions',
              routeName: 'account-operator-missions'
            }
          ]
        },
        {
          title: 'Token',
          icon: 'mdi-circle-multiple-outline',
          routeName: 'account-operator-token',
          children: [
            {
              title: 'Users',
              routeName: 'account-operator-token-users'
            },
            {
              title: 'Token Management',
              routeName: 'account-operator-token-management'
            },
            {
              title: 'Payment Limits',
              routeName: 'account-operator-token-payment-limits'
            },
            {
              title: 'Withdrawal Requests',
              routeName: 'account-operator-token-withdraw-requests'
            },
            {
              title: 'Token Sale',
              routeName: 'account-operator-token-sale'
            },
            {
              title: 'Stats',
              routeName: 'account-operator-token-stats'
            }
          ]
        },
        {
          title: 'CopyStake',
          icon: 'mdi-podcast',
          routeName: 'account-operator-copy-stake',
          visible: this.$role.can.read('streamers'),
          children: [
            {
              title: 'Guidance',
              routeName: 'account-operator-copy-stake-guidance'
            },
            {
              title: 'Streamers',
              routeName: 'account-operator-copy-stake-streamers'
            },
            {
              title: 'Stats',
              routeName: 'account-operator-copy-stake-stats'
            },
            {
              title: 'Streaming Schedule',
              routeName: 'account-operator-copy-stake-streaming-schedule'
            },
            {
              title: 'Game Providers Report',
              routeName: 'account-operator-copy-stake-game-providers'
            },
            {
              title: 'Games Whitelist',
              routeName: 'account-operator-copy-stake-game-whitelist'
            },
            {
              title: 'Players Blacklist',
              routeName: 'account-operator-copy-stake-user-blacklist'
            }
          ]
        },
        {
          icon: 'mdi-cog-outline',
          title: 'Settings',
          routeName: 'account-operator-settings'
        }
      ].filter((menuLink: SuperAdminMenuLink) => menuLink?.visible !== false);
    },
    showMintButton(): boolean {
      return (
        !this.activeOperator?.minted &&
        !this.activeOperator?.deployed &&
        !this.activeOperator?.test &&
        this.$role.can.update('super_admin_mint')
      );
    },
    showDeployButton(): boolean {
      return (
        this.activeOperator?.minted &&
        !this.activeOperator?.deployed &&
        !this.activeOperator?.test &&
        this.$role.can.read('super_admin_deploy')
      );
    },
    showWidgetButtons(): boolean {
      return this.activeOperator?.status === Status.on;
    },
    isWidgetEnabled(): boolean {
      return this.activeOperator?.widgetEnabled;
    },
    lastActiveOperator(): SuperAdminGameProviderOperator {
      const id = localStorage.getItem('superAdminOperatorId');
      const firstOperator = this.operators[0];

      if (!id) return firstOperator;

      const activeOperator = this.operators.find(
        (operator: SuperAdminGameProviderOperator) => operator.id === +id
      );

      if (!activeOperator) return firstOperator;

      return activeOperator;
    }
  },
  async created(): Promise<void> {
    await this.loadAccount();
  },
  watch: {
    operators: {
      handler(val: SuperAdminGameProviderOperator[]): void {
        if (!val.length) return;

        this.activeOperator = this.lastActiveOperator;
      },
      immediate: true
    },
    async activeOperator(): Promise<void> {
      this.setOperatorId(this.activeOperator.id);
      this.setSelectedOperator(this.activeOperator);
      await this.getOperatorDetailsById(this.activeOperator.id);
    },
    operator(): void {
      this.getProviders();
      this.getApiKeys();
      this.getOperatorMode();
    }
  },
  methods: {
    ...mapActions('SuperAdmin', [
      'getAccounts',
      'getOperatorDetailsById',
      'getAccountDetailsById'
    ]),
    ...mapActions('Onboarding', [
      'getProviders',
      'getApiKeys',
      'getOperatorMode'
    ]),
    ...mapActions('Operator', ['updateOperatorWidgetStatus']),
    ...mapMutations('SuperAdmin', ['setOperatorId', 'setAccount']),
    ...mapMutations('Onboarding', ['setSelectedOperator']),
    async mintHandler(): Promise<void> {
      this.isLoading.mint = true;
      const confirmData = await this.$refs.confirm.openDialog({
        title: `Mint`,
        text: `Are you sure yo want to mint “${this.tokenSymbol}“ in database?`,
        controls: {
          submit: {
            color: 'primary',
            text: 'Confirm'
          }
        }
      });

      if (!confirmData) {
        this.isLoading.mint = false;
        return;
      }

      mintOperator(this.activeOperator.id)
        .then(() => {
          this.$toast.success('Mint in database is successful');
        })
        .catch((e: AxiosError) => {
          errorToastMessage(e);
        })
        .finally(async () => {
          await this.loadAccount();
          this.isLoading.mint = false;
        });
    },
    async deployHandler(): Promise<void> {
      this.isLoading.deploy = true;
      const confirmData = await this.$refs.confirm.openDialog({
        title: `Deploy`,
        text: `Are you sure yo want to deploy “${this.tokenSymbol}“ in blockchain?`,
        controls: {
          submit: {
            color: 'primary',
            text: 'Confirm'
          }
        }
      });

      if (!confirmData) {
        this.isLoading.deploy = false;
        return;
      }

      deploySmartContract(this.activeOperator.id)
        .then(() => {
          this.$toast.success('Smart contract has deployed');
        })
        .catch((e: AxiosError) => {
          errorToastMessage(e);
        })
        .finally(async () => {
          await this.loadAccount();
          this.isLoading.deploy = false;
        });
    },
    selectOperator(operator: SuperAdminGameProviderOperator): void {
      this.activeOperator = operator;
      localStorage.setItem('superAdminOperatorId', `${operator.id}`);
    },
    addAccount(): void {
      this.openCreateAccountDialog = true;
    },
    async enableWidget(value: boolean): Promise<void> {
      if (!this.activeOperator) {
        return;
      }

      await this.updateOperatorWidgetStatus({
        operatorId: this.activeOperator.id,
        enabled: value
      });

      await this.loadAccount();
    },
    async loadAccount(): Promise<void> {
      await this.getAccountDetailsById(this.$route.params.id);
    }
  },

  beforeDestroy(): void {
    localStorage.removeItem('superAdminOperatorId');
    this.setAccount(null);
  }
};
